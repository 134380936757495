import React from "react";
import '../styles/footer-styles.css'

function Footer() {
    return (
        <section id="events">
            <footer className="footer">
                <div className="mailing-list">
                    <div className="line-with-shadow"></div>
                    <h5>SIGN UP FOR OUR MAILING LISTS</h5>
                    <div className="mailing-links">
                        <a
                            href="https://apply.college.brown.edu/register/?id=ba6e1b28-15de-43f1-8f7c-4444c7958180"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Brown
                        </a>
                        <a
                            href="https://apply.college.columbia.edu/register/request"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Columbia
                        </a>
                        <a
                            href="https://prospects.uchicago.edu/register/?id=43a61f54550842e3a7539edb90d99402"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            UChicago
                        </a>
                        <a
                            href="https://admissions.vanderbilt.edu/request/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Vanderbilt
                        </a>
                    </div>
                </div>
            </footer>
        </section>
    );
}

export default Footer;
