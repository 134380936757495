import React from "react";
import '../styles/navBar-styles.css';
import ShieldBrown from '../images/shield-brown-01.svg';
import ShieldColumbia from '../images/shield-columbia.svg';
import ShieldUChicago from '../images/shield-uchicago.svg';
import ShieldVanderbilt from '../images/shield-vanderbilt.svg';
import { Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

function Navbar() {
    return (
        <>
            <header>
                <nav role="navigation" className="hamburger-container" aria-label="Main">

                    <a href="https://admission.brown.edu/" target="_blank" rel="noreferrer"><img className="hamburger-icon" src={ShieldBrown} alt="Brown Admissions" /></a>
                    <a href="https://undergrad.admissions.columbia.edu/" target="_blank" rel="noreferrer"><img className="hamburger-icon" src={ShieldColumbia} alt="Columbia Admissions" /></a>
                    <a href="https://collegeadmissions.uchicago.edu/" target="_blank" rel="noreferrer"><img className="hamburger-icon" src={ShieldUChicago} alt="UChicago Admissions" /></a>
                    <a href="https://admissions.vanderbilt.edu/" target="_blank" rel="noreferrer"><img className="hamburger-icon" src={ShieldVanderbilt} alt="Vanderbilt Admissions" /></a>

                    <Row>
                        <Col>
                            <Link to="/#details" className='hamburger-links'>
                                <h5 className='hamburger-text'>DETAILS</h5>
                            </Link>
                        </Col>

                        <Col>
                            <Link to="/#events" className='hamburger-links'>
                                <h5 className='hamburger-text'>EVENTS</h5>
                            </Link>
                        </Col>

                        <Col>
                            <Link to="/#footer" className='hamburger-links'>
                                <h5 className='hamburger-text'>MAILING</h5>
                            </Link>
                        </Col>
                    </Row>
                </nav>
            </header>
        </>
    );
}

export default Navbar;
