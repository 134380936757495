import React from 'react';
// import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import Layout from './src/components/layout';

export function wrapPageElement({ element, props }) {
    return (
        <Layout {...props}>{element}</Layout>
    );
}

// export function wrapRootElement({ element }) {
//     return (
//         <ContentfulLivePreviewProvider locale="en-US">{element}</ContentfulLivePreviewProvider>
//     );
// }
